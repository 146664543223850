import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Creating an Account" prev={{
  name: 'Account Setup',
  path: '/account-setup'
}} next={{
  name: 'Creating a Company',
  path: '/creating-a-company'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Message sx={{
      mb: 4
    }} mdxType="Message">
If you use QuickBooks Online and you intend to connect your QuickBooks Online account to your Simple RM account, you can follow a <Link href="/help-center/account-creation-with-quickbooks-online/" mdxType="Link">simplified account setup flow</Link>.
    </Message>
    <p>{`This is how you create a user account when you do not intend to connect your SRM account to QuickBooks Online.`}</p>
    <p>{`Go to the `}<a target="_blank" href='https://app.simplereviewmanagement.com/create-account'>{`create account page`}</a>{`.`}</p>
    <h2 {...{
      "id": "create-an-account-using-google"
    }}>{`Create an Account Using Google`}</h2>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
This is the recommended method for creating a user account, as it also completes part of your company setup at the same time.
    </Message>
    <p>{`Start by clicking the “Sign up with Google” button.`}</p>
    <p>{`You will be on Googles pages for the following two steps.`}</p>
    <h4 {...{
      "id": "sign-in"
    }}>{`Sign In`}</h4>
    <p>{`You must be signed into your Google account in order to grant Simple RM permission to Google My Business in step two.`}</p>
    <p>{`If you are already signed in to a Google account you may be brought directly to step two.`}</p>
    <p>{`Make sure you are signed into the Google user account that is associated with the Google My Business account you are trying to connect.`}</p>
    <h4 {...{
      "id": "grant-permission"
    }}>{`Grant Permission`}</h4>
    <p>{`You will now be presented with a screen that explains what it means to connect Simple Review Management to your Google My Business listing.`}</p>
    <p>{`Once you have read through everything and you are comfortable to proceed, confirm you would like to give Simple Review Management the permissions listed. `}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
You will see you are granting Simple RM delete permission. This is only because Google does not give us the ability to request view permissions and not this one. We will never delete anything in your account.
    </Message>
    <p>{`When you grant permission you will be brought back to the Simple Review Management app.`}</p>
    <p>{`Your user account is now created and you can finish setting up your first company.`}</p>
    <h2 {...{
      "id": "create-an-account-without-using-google"
    }}>{`Create an Account Without Using Google`}</h2>
    <p>{`Fill out the form and click the create button.`}</p>
    <p>{`When you do you will see a screen that says an email has been sent to the email address used to create the account.`}</p>
    <p>{`Find the email in your inbox and follow the link to verify you own the email address. `}</p>
    <p>{`Once your email address is verified you have successfully created a Simple RM account.`}</p>
    <p>{`Proceed to the next article to create a company in your account.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      